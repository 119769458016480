import React from 'react';
import './Navbar.css';
import pitchdeck from '../CoinStarz Pitchdeck 1.83.docx.pdf';
import logo from "../CSZWhiteLogo.png";
import menu from "./hamburger.png"
import {Link} from "react-router-dom";

export default function Navbar() {
  return (
    <div className="nav-grid">
        <div className='nav-left'></div>
            <nav className='nav-middle'>
                <img id="nav-logo" src={logo} alt="CSZ Logo"/>
                <ul id="nav-links">
                    <li className='link-li'>
                        <Link to="/">Home</Link>     
                    </li>
                    <li>
                        <a target="_blank" rel="noreferrer" href={pitchdeck}>Pitchdeck</a>
                    </li>
                </ul>
                <nav role="navigation" className="mobile-nav">
                    <ul>
                        <li><a><img src={menu} alt="Menu" /></a>
                        <ul class="dropdown">
                            <li><Link to="/">Home</Link> </li>
                            <li><a target="_blank" rel="noreferrer" href={pitchdeck}>Pitchdeck</a></li>
                        </ul>
                        </li>
                    </ul>
                </nav>
            </nav>
        <div className="nav-right">
        </div>
    </div>
  )
}
