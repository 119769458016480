import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import React from 'react';
import Home from './pages/Home.js';
import Navbar from "./pages/Navbar";
import './App.css';

function App() {
  return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>   
      </Router>
  );
}

export default App;
