import React from 'react';
import './Home.css';
import logo from "../CSZWhiteLogo.png";
import pitchdeck from '../CoinStarz Pitchdeck 1.83.docx.pdf';
import IG from "./HoIG.png";
import CSZ from "./HoCSZ.png";
import Fed from "./FedCSZ.png";
import Creators from "./Creators.png";
import Telegram from "../SMIcons/Telegram.png";
import Instagram from "../SMIcons/Instagram.png";
import Facebook from "../SMIcons/Facebook.png";
import Reddit from "../SMIcons/Reddit.png";
import Twitter from "../SMIcons/Twitter.png";
import Discord from "../SMIcons/Discord.png";

function Home() {
    return(
        <div id="home-container">
            <div className='feature-left'></div>
            <div className='feature-middle'>
                <div id="main-feature-links">
                    <div id="main-feature-text">
                        <h1>GET PAID WITH CRYPTO NOW</h1>
                        <h3>We support stars, online and offline,<br /> who produce high quality content.</h3>
                    </div>
                    <div id="main-feature-buttons">
                        <button id="pitch-deck-button" className="feature-button" ><label><a target="_blank" rel="noreferrer" href={pitchdeck}><h2>Pitchdeck</h2></a></label></button>
                        <button id="telegram-button" className="feature-button"><label><a href="https://t.me/+lSnfVWp95IpiYTQx"><h2>Join Our Telegram</h2></a></label></button>
                        <button id="buy-tokens-button" className="buy-feature-button"><label><h2>Buy CoinStarz Tokens</h2></label></button>
                    </div>
                </div>
                <div id="picture-container">
                    <img id="influencer-graphic" src={IG} alt="Influencer's Platform" />
                    <img id="platform-graphic" src={CSZ} alt="CSZ Platform" />
                </div>
            </div>
            <div className='feature-right'></div>

            <div className='spacer-1'></div>

            <div className='what-is-left'></div>
            <div className='what-is-middle'>
                <div className='what-is-container'>
                    <div id="what-is-picture">
                        <img id="what-is-graphic" src={Fed} alt="CSZ Platform" />
                    </div>
                    <div id="what-is-text">
                        <h3 id="what-is-title">What is CoinStarz?</h3>
                        <h1 id="platform-h1">CoinStarz Platform</h1>
                        <h3>CoinStarz makes Crypto and NFTs easier to use.</h3>
                        <h3>We provide a platform to help you put all of your crypto addresses in one place: making it easier for you to accept many various Cryptos or trade NFTs.</h3>
                        <h3>Everyone can be a star and start accepting crypto today!</h3>
                        <button id="sign-up"><label><h2>Sign Up</h2></label></button>
                    </div>
                </div>
            </div>
            <div className='what-is-right'></div>

            <div className='spacer-1'></div>

            <div className="token-left"></div>
            <div className="token-middle">
                <div className='token-container'>
                    <div id="token-information">
                        <h3 id="token-info-header">Coming Soon!</h3>
                        <h1>CoinStarz Token</h1>
                        <h3>Receive your crypto directly, with no middleman, banking app, or website that gets a cut of your hard-earned pay.</h3>
                        <h3>You will only need ONE click to our platform to display all the cryptocurrencies that you accept, with all of their corresponding addresses, ready to go.</h3>
                        <button id="token-info-button"><label><h2>Token Info</h2></label></button>
                    </div>
                    <div>
                        <img id="token-graphic" src={Creators} alt="CSZ" />
                    </div>
                </div>
            </div>
            <div className="token-right"></div>

            <div className='spacer-1'></div>

            <div className="ready-left"></div>
            <div className="ready-middle">
                <div className='ready-container'>
                    <div id="ready-text">
                        <h1>Ready to start accepting crypto?</h1>
                        <h3>Crypto as a payment method is inevitable. You want to be on the leading-edge of this highly innovative frontier.</h3>
                    </div>
                    <div class="button-holder">
                        <button className="ready-button" id="ready-sign-up"><label><h2>Sign Up</h2></label></button>
                    </div>
                </div>
            </div>
            <div className="ready-right"></div>
            
            <div className="spacer-1"></div>
            
            <div className="footer-left"></div>
            <div className="footer-middle">
                <a target="_blank" rel="noreferrer" href="https://coinstarz.com/"><img id="footer-logo" src={logo} alt="CSZ" /></a>
                <div id="social-media-section">
                    <a href="https://t.me/+lSnfVWp95IpiYTQx"><img className="social-link" src={Telegram} alt="CSZ" /></a>
                    <a href="https://twitter.com/CoinStarzToken"><img className="social-link" src={Twitter} alt="CSZ" /></a>
                    <a href="https://www.instagram.com/coinstarztoken/"><img className="social-link" src={Instagram} alt="CSZ" /></a>
                    <a href="#:"><img class="social-link" src={Reddit} alt="CSZ" /></a>
                    <a href="#:"><img class="social-link" src={Discord} alt="CSZ" /></a>
                    <a href="#:"><img class="social-link" src={Facebook} alt="CSZ" /></a>
                </div>
            </div>
            <div className="footer-right"></div>
            
        </div>
    )
}

export default Home;